import axios from "axios";
import router from "./router";
import { Message } from 'element-ui';

let http = axios.create({
  baseURL: "https://pc.amour888.com/api",
  timeout: 10000,
});

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    config.headers.detect_var = window.localStorage.getItem("selLanguage");
    config.headers["Accept-Language"] = window.localStorage.getItem("selLanguage");
    if(config.url === 'login/login' || config.url == 'Common/getAgreement' || config.url == 'login/getLanguage'){
      return config;
    }
    if (config.url === "Upload/uploadImage") {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers.Authorization = window.localStorage.getItem("token");
    return config;
  },
  (err) => {
    Message({
      message: "网络错误",
      type: "warning",
    });
    console.log(err,window.localStorage.getItem('client_id'));
  }
);

// 响应拦截器
http.interceptors.response.use(
  (Response) => {
    if (Response.data.status === -1) {
      // Message({
      //   message: Response.data.message,
      //   type: "warning",
      // });
      setTimeout(()=>{
        router.push({
          path:'/login'
        });
      },1000)
      return Response.data;
    } else if (Response.data.status === 400) {
      Message({
        message: Response.data.message,
        type: "warning",
      });
      return Response.data;
    } else if (Response.data.status) {
      return Response.data;
    }
    console.log(Response)
  },
  (error) => {
    console.error(error, "error");
  }
);

// post请求
const post = (url, data) => http.post(url, data);
const get = (url, data) => http.get(url, {
  params:{...data}
});
const host = "pc.amour888.com/";
const wssUrl = 'wss://'+host+'wss';
const baseURL = "https://admin.amour888.com";

export default { post, get, baseURL,wssUrl };
